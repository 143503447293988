import { h, Component } from 'preact';
import { Router } from 'preact-router';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import EventPage from './EventsPage';
import Nav from '../components/Nav/Nav';
import ThemeProvider from '../components/ThemeToggle/ThemeProvider';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import ScrollToTop from '../components/ScrolltoTop/ScrolltoTop';

const NotFound = () => <h1>Page Not Found</h1>;

class App extends Component {
  constructor() {
    super();
    this.state = {
      currentPath: '/ui/',
    };
  }

  // Update the current path whenever the route changes
  handleRouteChange = (event) => {
    this.setState({ currentPath: event.url });
  };

  render() {
    const routes = {
      '^/ui$': 'Home',
      '^/ui/login.*$': 'Login',
      '^/ui/events/(?<camera>[^/]+)$': 'Event Details for {camera}',
    };

    const { currentPath } = this.state;

    return (
      <ThemeProvider>
        <div>
          {/* Pass the currentPath to Nav */}
          <Nav currentRoute={currentPath} />
          <Breadcrumbs path={currentPath} routes={routes} />
          <div class='layout'>
            <Router onChange={this.handleRouteChange}>
              <HomePage path='/ui/' />
              <LoginPage path='/ui/login' />
              <EventPage path='/ui/events/:camera' />
              <NotFound default />
            </Router>
            <ScrollToTop />
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default App;
