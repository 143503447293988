import { h, Component } from 'preact';
import MyIcon from '../../public/CarriageLogo.svg';
import ThemeToggle from '../ThemeToggle/ThemeToggle';
import './nav.css';

class Nav extends Component {
  constructor() {
    super();
    this.state = {
      isDropdownOpen: false,
    };
  }

  handleLogout = () => {
    localStorage.removeItem('token');
    alert('Logged out');
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  // Determine the page title based on the current route
  getPageTitle = () => {
    const { currentRoute } = this.props;
    if (currentRoute.startsWith('/ui/login')) return 'Login';
    if (currentRoute.startsWith('/ui/events')) return 'Event Details';
    return 'Carriage';
  };

  render() {
    const { isDropdownOpen } = this.state;

    return (
      <nav class='navigation'>
        <div class='nav'>
          <a href='/ui/'>
            <img
              src={MyIcon}
              alt='carriage-logo'
              class='carriage-icon'
              href='/ui'
            />
          </a>
          <h1>{this.getPageTitle()}</h1>
        </div>
        <div class='dropdown-container'>
          <button
            onClick={this.toggleDropdown}
            class='hamburger-menu'
            aria-label='Toggle menu'
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_429_11066)'>
                <path
                  d='M3 6.00092H21M3 12.0009H21M3 18.0009H21'
                  stroke-width='2.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_429_11066'>
                  <rect
                    width='24'
                    height='24'
                    fill='white'
                    transform='translate(0 0.000915527)'
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          {isDropdownOpen && (
            <ul class='dropdown-menu'>
              <li>
                <button onClick={this.handleLogout} class='btn-dropdown'>
                  Logout
                </button>
              </li>
              <li>
                <ThemeToggle className='btn-dropdown' />
              </li>
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

export default Nav;
